import { useState, useEffect } from "react";
import { Input, Form, notification, Spin, Drawer } from "antd";
import { Button } from "@material-tailwind/react";
import CustomButton from "../CustomButton";
import { base_api_request } from "../../utils/api";
import { Region } from "../../types";
import { RouteConstants } from "../../utils/helpers/RouteConstants";

interface modalProps {
  isEditOpened: boolean;
  handleClosed: () => void;
  dataToEdit: Region;
  handleReFetch: () => void;
}

const EditRegion = ({
  handleClosed,
  isEditOpened,
  handleReFetch,
  dataToEdit,
}: modalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      //console.log(values);
      await base_api_request.put(
        `${RouteConstants.ALL_REGIONS}/${dataToEdit?.id}`,
        {
          name: values.name,
          code: values.code,
        }
      );

      notification.success({
        message: "Region edited successfully",
      });

      setLoading(false);
      handleReFetch();
      handleClosed();
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message || e?.message,
      });
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    form.setFieldValue("code", dataToEdit?.code);
    form.setFieldValue("name", dataToEdit?.name);

    // eslint-disable-next-line
  }, [dataToEdit]);

  return (
    <Drawer
      title="Edit Town"
      placement="right"
      onClose={handleClosed}
      open={isEditOpened}
      zIndex={100}
      size="default"
    >
      <Spin spinning={loading}>
        <div>
          <Form
            form={form}
            className="mt-5"
            layout="vertical"
            autoComplete="none"
          >
            <Form.Item
              required
              className="w-full"
              label="Region Code"
              name="code"
            >
              <Input size="large" placeholder="CODE" />
            </Form.Item>

            <Form.Item
              required
              className="w-full mt-4"
              label="Region Name"
              name="name"
            >
              <Input size="large" placeholder="Name" />
            </Form.Item>
          </Form>
          <div className="flex w-full justify-end items-end mt-10">
            <div className="flex items-center gap-3">
              <CustomButton data-testid="submitNewTown" onClick={submit}>
                <div>Submit</div>
              </CustomButton>
              <Button
                data-testid="closeNewTown"
                disabled={loading}
                variant="outlined"
                size="sm"
                onClick={handleClosed}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
};

export default EditRegion;
